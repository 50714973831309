import React from "react";
import Request from "./Request";
import HomeHeader from "./HomeHeader";
import Advice from "./Advice";

const Home = () => {
    return (
        <div>
            <HomeHeader/>
            <Advice/>
            <Request/>
            {/*<NewsKoko/>*/}
        </div>
)}
export default Home;
