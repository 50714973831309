import React from "react";
import './App.css';
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";

import Home from './Components/Home';
import {Article} from './Components/Article';
import {Akne} from "./Components/Akne";
import {CleanS} from "./Components/CleanS";
import Study from "./Components/Study";
import SchedulePage from "./Components/SchedulePage";



function App() {
  return (
    <div className={'home'}>
      <Navbar/>
         <Routes>
            <Route exact path="/" element={<Home/>}/>
            {/* <Route path="/users" component={Users}/> */}
            <Route path="/article" element={<Article/>}/>
            <Route path="/akne" element={<Akne/>}/>
            <Route path="/cleans" element={<CleanS/>}/>
             <Route path="/study" component={Study}/>
             <Route path="/schedule" component={SchedulePage}/>
         </Routes>
         <Footer/>
    </div>
  );
}

export default App;
