import React from 'react';

import {scheduleDataAll} from "./untils/contentScheduleAll";
import ScheduleAll from "./ScheduleAll";

const ScheduleBlockAll  =()=> {

    return (
        <div>
            <div>
                {scheduleDataAll.map(({ data, description }) => (
                    <ScheduleAll data={data} description={description} />
                ))}
            </div>
        </div>
    )
}

export default ScheduleBlockAll;