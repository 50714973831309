import React from 'react';
import knowledge from '../assets/icons/knowledge.svg';
import certificate from '../assets/icons/certificate.svg';
import website from '../assets/icons/website.svg';
import molecele from '../imeges/molecule.svg';

const StudyAdvantage = () => {

    return (
        <div className={'advBcg'}>
            <img  className={'bcgImg'} src={molecele} alt=""/>
            <header className={'advHeader blue'}>
                <h2>Что дает обучение</h2>
            </header>

            <div className={'advBody'}>
                <div className={'advCard'}>
                    <img src={knowledge} alt=""/>
                    <h3>Гарантия результата работы</h3>
                    <p>Вы получите максимум знаний для эффективной и безопасной работы с профессиональной косметикой!</p>
                </div>
                <div className={'advCard'}>
                    <img src={certificate} alt=""/>
                    <h3>Сертификат специалиста</h3>
                    <p>Для начала работы с немецкой косметикой КОКО dermavidials® необходимо пройти обязательное обучение!</p>
                </div>
                <div className={'advCard'}>
                    <img src={website} alt=""/>
                    <h3>Заказ препаратов через сайт</h3>
                    <p>Препараты на сайте могут заказывать только клиенты, прошедшие обучение! </p>
                </div>
            </div>

        </div>
    );
}

export default StudyAdvantage;