import React from 'react';
import Accordion from './Accordion';
import {accordionData} from "./untils/contentAccord";

const Question = () => {
    return (
        <div className={'advBcg'}>
            <div>
                <h2 className={'blue advHeader' }>Часто задаваемые вопросы</h2>
            </div>
            <div className="accordion">
                {accordionData.map(({ title, content }) => (
                    <Accordion title={title} content={content} />
                ))}
            </div>
        </div>
    );
};
export default Question;
