import React, {useState} from 'react';
import {scheduleData} from "./untils/contentSchedule";
import Schedule from "./Schedule";
import ScheduleBlockAll from "./ScheduleBlockAll";

const ScheduleBlock  =()=> {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={'advBcg advBody'}>
            <div>
                <h2 className={'blue'}>Расписание семинаров</h2>
            </div>
            <div className={'warningSchedule'}>
                <p className={'warnText'}>Предварительная запись обязательна. Записаться на курс можно через online-формулу, либо по телефону</p>
                <a className='link' href="tel: +79456075655">8 (495) 607 56 55</a>
            </div>
            <div className={'scheduleBlock'}>
                <div>
                    <div>
                        {scheduleData.map(({ data, description }) => (
                            <Schedule data={data} description={description} />
                        ))}
                        {isActive && <div ><ScheduleBlockAll/></div>}
                    </div>
                </div>
                <div className={'scheduleBtnAll'} onClick ={() => setIsActive(!isActive)}>
                    {isActive ? 'Скрыть' : 'Посмотреть все расписание'}
                </div>
            </div>
        </div>
    )
}

export default ScheduleBlock;