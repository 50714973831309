import React from 'react';
import CheckList  from '../img/ChekList.svg';
import Coll  from '../img/Coll.svg';

const Request = () => {

    return (
        <div >
            <h2>Оставить заявку на консультацию</h2>
            <p>Как проходит консультация</p>
            <div className={'conSu bord'}>
                    <div className='Card'>
                        <h3>1 шаг</h3>
                        <img  className='stepImg' src={CheckList}/>
                        <div>
                            <p>
                                Заполнить анкету
                            </p>
                        </div>
                    </div>
                    <div className='Card'>
                        <h3>2 шаг</h3>
                        <img  className='stepImg' src={Coll}/>
                        <div>
                            <p>
                                С вами свяжется наш консультант
                            </p>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Request;
