import React from "react";
import StudyHeader from "./StudyHeader";
import StudyAdvantage from "./StudyAdvantage";
import ScheduleBlock from "./ScheduleBlock";
import Articles from "./Articles";
import Question from "./Question";


const Study = () => {
    return (
        <div>
            <StudyHeader/>
            <StudyAdvantage/>
            <ScheduleBlock/>
            <Articles/>
            <Question/>
        </div>
    )}
export default Study;