import React, {Component} from 'react';

class Schedule extends Component {
    render() {
        return (
            <div className={'advBcg attBlock'}>
                <header className={'advHeader'}>
                    <div className={'header-adv'}>
                        <h2 className={'blue'}>Расписание</h2>
                    </div>
                </header>
                <div className={'attention'}>
                        <div className={'attentionText'}>
                        <p>!Предварительная запись обязательна. Записаться на курс можно через online-формулу,
                    либо по телефону</p>
                    <a className='a' href="tel: +79456075626">+7 (495) 607 56 55</a>
                </div>


                </div>
            </div>
        );
    }
}

export default Schedule;