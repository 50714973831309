import React, {useState} from 'react';
import OrderBtn from "./UI/Buttons/OrderBtn";


const ScheduleAll = ({ data, description }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="scheduleCard">
            <div className="scheduleData blue">
                <div>
                    {data}
                </div>
            </div>
            <div className="scheduleDescription">
                {description}
            </div>
            <div className={'scheduleData'}>
                <OrderBtn onClick={handleShow}>Записаться</OrderBtn>
            </div>
        </div>
    );
};

export default ScheduleAll;