import React from 'react';
import classes from "./Orderbtn.module.css";

const LoginButton = ({...props}) => {
    return (
        <div>
            <button {...props} className={classes.ordbtn}>
            </button>
        </div>
    );
};

export default LoginButton;
