import React from 'react';
import HeadPhoto from "../img/HeadPhoto.png";
const HomeHeader = () => {
    return (
        <div className={'conSu bord'}>
                <div className='Card'><h1>Проблемы с кожей?</h1>
                    <div>
                        <div><p>Более 10 000 человек нашли своё решение, вместе с </p> <h5>KOKO dermavidials</h5></div>

                    </div>
                </div>
                <div className='girlPhoto'><img className='PhotoHeader' src={HeadPhoto} alt="Photo Girl" /></div>
        </div>
    );
};

export default HomeHeader;