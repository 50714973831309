import React from "react";
import '../Components/styles/FirstHeader.css'
import ScheduleBlock from "./ScheduleBlock";


const SchedulePage = () => {
    return (
        <div>
            <ScheduleBlock/>
        </div>
    )}
export default SchedulePage;
