import React from 'react';
import firstP from '../imeges/homeHed1.png';

const StudyHeader = () => {
        return (
            <div className={'blocs'}>
                <article>
                    <header>
                        <h2 className={'blue'}>Программа обучения специалистов </h2>
                        <h3 className={'blue'}>по работе с препаратами КОКО dermaviduals®</h3>
                        <p>Представительство  КОКО dermavidials® в России (г. Москва) приглашает на обучение по работе с профессиональной косметикой КОКО dermavidials®</p>
                        <p>На обучении Вы получите подробную информацию о всех препаратах линии, а также печатные пособия. В программу обучения входит
                            <span className={'h5'}> обширная теоретическая подготовка и практические занятия.</span>
                        </p>
                    </header>
                </article>
                <div>
                <img className={'fimg'} src={firstP} alt=""/>
                </div>
            </div>
        );
}
export default StudyHeader;