import React from 'react';
import anke from '../img/anke.jpg';
import pigment from '../img/pigmentation.jpg';
import {Link} from "react-router-dom";

const Advice = () => {
    return (
        <div>
            <h2>Консультация по проблеме:</h2>

            <div className={'conSu bord'}>
                <div>
                    <img className='PhotoAdvice' src={anke}/>
                </div>
                <div className='BlockText'>
                    <h3>Акне</h3>
                    <div className={'firstPara'}>В патогенезе акне выделяют два основных механизма: гиперфункциию сальных желез и гиперкератоз.
                        Начальные морфологические изменения при акне связаны с нарушением процессов ороговения</div>
                    <p>
                        В патогенезе акне выделяют два основных механизма: гиперфункциию сальных желез и гиперкератоз.
                        Начальные морфологические изменения при акне связаны с нарушением процессов ороговения
                        в эпидермисе устья волосяного фолликула и образованием микрокомедонов,
                        которые закупоривают выводной проток сальной железы.
                        <Link to="/akne">Подробнее : </Link>
                    </p>
                </div>
            </div>
            <div className={'conSu bord'}>
                <div  className='BlockText'>
                    <h3>Чувствительная кожа</h3>
                    <div className={'firstPara'}> Общее ухудшение экологической обстановки, ослабление иммунитета, неправильное питание и
                        нерациональное применение косметики – основные причины того, что в развитых странах до 50%</div>
                    <p>
                        Общее ухудшение экологической обстановки, ослабление иммунитета, неправильное питание и
                        нерациональное применение косметики – основные причины того, что в развитых странах до 50%
                        населения обладают теми или иными проблемами, связанными с повышенной чувствительностью кожи.
                        В зависимости от этиологии, их принято разделять на три группы: аллергии на различные субстанции,
                        нарушения барьерной функции эпидермиса и химическая сверхчувствительность. Группа риска – люди с
                        кожей сухого типа.
                        <Link to="/cleans">Подробнее :</Link>
                    </p>
                </div>
                <div className='viPlayer'>
                    <img className='PhotoAdvice' src={pigment}/>
                </div>
            </div>
        </div>
    );
};

export default Advice;