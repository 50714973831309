import React from "react";
import {Navbar, Nav}  from "react-bootstrap";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import logo from "../img/logo2.svg";
import email from "../assets/icons/email.svg";
const Styles = styled.div`
    a, .navbar-brand, .navbar-nav, nav.link {
      color: #000000;
      font-size: large;
      text-decoration: none;
      &:hover {
        color: #adb5bd;
      }
    }`

const Navibar = ()=> {

    return(
        <Styles>
            <header>
    <Navbar collapseOnSelect expand='lg' className={'navbarBlock'}>

            <Navbar.Brand><a href='https://koko.pro/'><img src={logo} alt="logoDermaviduals"/></a></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link className={'ml '}><Link to="/">главная</Link></Nav.Link>
                <Nav.Link className={'ml'}> <Link to="/study">обучение</Link></Nav.Link>
                <Nav.Link className={'ml'}> <Link to="/article">статьи</Link></Nav.Link>

            </Nav>
            <Nav>
                <a className='link' href="mailto:info@koko-dermaviduals.ru"><img src={email} alt="logoEmail" /></a>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
            </header>
        </Styles>
    );
};
export default Navibar;
