import React from "react";
import akne from "../img/anke.jpg";


export const Akne = () => (

    <div className={'conSu'}>
        <div>
            <h1>Акне</h1>
            <img src={akne} alt={"vitamins"} className={'statImg'}/>
            <div className={'firstPara'}>В патогенезе акне выделяют два основных механизма: гиперфункциию сальных желез и гиперкератоз. Начальные морфологические изменения при акне связаны с нарушением процессов ороговения в эпидермисе устья волосяного фолликула и образованием микрокомедонов, которые закупоривают выводной проток сальной железы.</div>
            <p>Современный подход к наружному лечению акне предлагает корнеотерапия – концепция, согласно которой многие патологические процессы в коже могут быть устранены путем восстановления полноценной структуры эпидермального барьера и нормализации химического состава липидов эпидермиса. Этот подход базируется на фундаментальном принципе outside–in therapy («терапия снаружи–внутрь»), сформулированном американским дерматологом Альбертом Клигманом. Согласно Клигману, липиды, нанесенные на поверхность кожи в составе косметического средства, способны проникать в эпидермис и включаться в химические процессы, протекающие в нем. Именно поэтому, кстати, в состав средств по уходу за проблемной кожей не должны входить вещества, способные повредить эпидермальный барьер.

                Косметика, разработанная в соответствии с концепцией корнеотерапии, призвана восстановить естественную структуру кожи и ее регенераторные функции. Кожа обладает мощным регенераторным потенциалом и благодарно реагирует на средства, учитывающие ее реальные нужды. Об этом говорит опыт применения дерматологической косметики с Дерма-Мембранными Структурами (DMS®), хорошо зарекомендовавшей себя не только в терапии акне, но и в качестве превентивного ухода при таких заболеваниях как – атопический и контактный дерматиты, а также в anti-age уходе.

                Основными активными субстратами DMS® являются фосфатидилхолин и церамиды. Структурно DMS® напоминают липидные бислои матрикса рогового слоя.</p>
        </div>
    </div>
)