export const scheduleDataAll =[
    {
        
        data: '5 окт.',
        description: `повышение квалификации/тематический`
    },
    {
        
        data: '12-13 окт.',
        description: `обучение`
    },
    {
        data: '18 окт.',
        description: `повышение квалификации/тематический`
    },
    {
        data: '27 окт.',
        description: `повышение квалификации/тематический`
    },
    {
        data: '9 ноя.',
        description: `повышение квалификации/тематический`
    },
    {
        data: '15-16 ноя.',
        description: `обучение`
    },
    {
        data: '24 ноя.',
        description: `повышение квалификации/тематический`
    },
    {
        data: '29 ноя.',
        description: `повышение квалификации/тематический`
    },
    {
        data: '7 дек.',
        description: `повышение квалификации/тематический`
    },
    {
        data: '14-15 дек.',
        description: `обучение`
    },
    {
        data: '22 дек.',
        description: `повышение квалификации/тематический`
    },
];