import React from 'react';
import knowledge from "../assets/icons/knowledge.svg";
import certificate from "../assets/icons/certificate.svg";
import website from "../assets/icons/website.svg";


const Articles = () => {

    return (
        <div className={'advBcg'}>
            <div className={'blue advHeader'}>
            <h2>Полезные статьи</h2>
            </div>
            <div className={'advBody'}>
                <div className={'advCard'}>
                    <img src={knowledge} alt=""/>
                    <p>Вы получите максимум знаний для эффективной и безопасной работы с профессиональной косметикой!</p>
                </div>
                <div className={'advCard'}>
                    <img src={certificate} alt=""/>
                    <p>Для начала работы с немецкой косметикой КОКО dermavidials® необходимо пройти обязательное обучение!</p>
                </div>
                <div className={'advCard'}>
                    <img src={website} alt=""/>
                    <p>Препараты на сайте могут заказывать только клиенты, прошедшие обучение! </p>
                </div>
            </div>
        </div>
    );
};

export default Articles;