import React from "react";
import pigment from "../img/pigmentation.jpg";


export const CleanS = () => (

    <div className={'conSu'}>
        <div>
            <h1>Чувствительная кожа</h1>
            <img src={pigment} alt={"vitamins"} className={'statImg'}/>
            <div className={'firstPara'}>Общее ухудшение экологической обстановки, ослабление иммунитета, неправильное питание и
                нерациональное применение косметики – основные причины того, что в развитых странах до 50%
                населения обладают теми или иными проблемами, связанными с повышенной чувствительностью кожи.
                В зависимости от этиологии, их принято разделять на три группы: аллергии на различные субстанции,
                нарушения барьерной функции эпидермиса и химическая сверхчувствительность. Группа риска – люди с
                кожей сухого типа.</div>

            <p>
                Точно подбирая и дозируя активные ингредиенты в соответствие с потребностями кожи конкретного клиента, косметолог своими руками может создавать на основе DMS® кремы по индивидуальным рецептам.

                Теория корнеологии говорит о сигнальной функции рогового слоя, с помощью которой происходит регуляция процессов в живом эпидермисе. Это одна из причин, по которой в составы для чувствительной кожи следует включать физиологичные или сходные с ними компоненты (церамиды, NMF, гиалуроновую кислоту). Во избежание возникновения аллергических реакций важно, чтобы они были представлены в виде чистых моносоставов. Для этих целей служит заключение их в состав особых транспортных частиц – липосом и наночастиц. Как и DMS®, они построены из фосфатидилхолина, способны проникать через липидный барьер, и, не нарушая его целостность, доставлять активные ингредиенты в глубокие слои кожи в неизмененном виде. В средствах корнеотерапии эти частицы заменяют энхансеры обычной косметики (например, органические растворители), которые не переносятся чувствительной кожей.

                Среди прочих необходимых ингредиентов для чувствительной кожи стоит особо отметить эмоленты – смягчающие и увлажняющие компоненты. Например, сквалан, масло ши. Они создают на поверхности кожи воздухопроницаемую защитную пленку, которая снижает потерю влаги, и, в отличие от минеральных масел, не угнетает регенеративной активности эпидермиса. Триглицериды, составляющие до 80% масло ши, являются субстратом для ферментативных систем кожи.
            </p>
        </div>
    </div>
)