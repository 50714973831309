import React from "react";

// import vk from '../assets/icons/vk.svg';
import email from '../assets/icons/email.svg';


const Footer =() => (
            <div className="footer firstPara">
                <div className="footer__block">
                <a className='link' href="https://koko.pro">dermaviduals.ru</a></div>
                <div className="footer__block">
                    <ul style={{margin: '0', padding: '0'}}>
               <li> <a className='link' href="tel:+78003339502">8 (800) 333 95 02</a></li>
                        <li><a className='link' href="tel: +79456075655">8 (495) 607 56 55</a></li>
               <li> <a className='link' href="tel: +79456075626">8 (495) 607 56 26</a></li>
                    </ul>
                </div>
                <div className="footer__block">
                <a className='link' href="mailto:info@koko-dermaviduals.ru"><img src={email} alt="logoEmail" /></a>
                {/*<a className='link' href="https://m.vk.com/rabotagsp2?from=post"><img src={vk} alt="logoVk"/></a>*/}
                </div>
            </div>

    )
export default Footer;
